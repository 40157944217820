import React from 'react'
import { Input, Label } from 'reactstrap'
import styled from '@emotion/styled'

const Error = styled.p`
  color: red
`;

export default function Field({ formik, name, placeholder, type = 'text', label, onChange, value, options }) {
  return (
    <>
    {label && <Label for={name}>{label}</Label>}
    {type === 'select'
    ? (
      <>
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        className="mb-3"
        onChange={onChange || formik.handleChange} 
        value={typeof value === 'string' ? value : formik.values[name]}
        autoComplete={"off"}>
          {options?.map(opt => (
            <option selected={opt.value === value} value={opt.value}>{opt.name}</option>
          ))}
          </Input>
          {formik?.errors?.[name] && <Error>{formik.errors[name]}</Error>}
          </>
    )
  : (
    <Input
        id={name}
        name={name}
        placeholder={placeholder}
        type={type}
        className="mb-3"
        onChange={onChange || formik.handleChange} 
        value={typeof value === 'string' ? value : formik.values[name]}
        autoComplete={"off"}/>
  )}
  {formik?.errors?.[name] && <Error>{formik.errors[name]}</Error>}

    </>
  )
}