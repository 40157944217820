import { useFormik } from 'formik'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'

import { getAction } from '../panel'
import Field from '../../components/field'
import List from '../../components/list'
import valeroService from '../../service'
import parseFields, { parseOption } from '../../utils'

const initialValues = {
  cerramiento_id: '',
  codigo_id: '',
  elemento_id: '',
  aislante_id: '',
  zona_id: ''
}

const headers = ['cerramiento_id','codigo_id','aislante_id','img']

const PanelDetails = () => {
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log('SUBMIT',values)
      valeroService.getForm(values).then(d => setResult(d))
    }
  })
  const [filter, setFilter] = useState(initialValues)
  const [data, setData] = useState(initialValues)
  const [result, setResult] = useState(null)

  const getData = async () => {
    const cerramiento_id = await valeroService.getAllEntity('cerramientos')
    const codigo_id = await valeroService.getAllEntity('codigos')
    const elemento_id = await valeroService.getAllEntity('elementos')
    const aislante_id = await valeroService.getAllEntity('productos')
    const zona_id = await valeroService.getAllEntity('zonas')

    cerramiento_id.unshift({name: '--Selecciona una opción--', value: null})
    codigo_id.unshift({name: '--Selecciona una opción--', value: null})
    elemento_id.unshift({name: '--Selecciona una opción--', value: null})
    aislante_id.unshift({name: '--Selecciona una opción--', value: null})
    zona_id.unshift({name: '--Selecciona una opción--', value: null})

    formik.setValues({
      cerramiento_id: cerramiento_id[0]._id,
      codigo_id: codigo_id[0]._id,
      elemento_id: elemento_id[0]._id,
      aislante_id: aislante_id[0]._id,
      zona_id: zona_id[0]._id
    })
    setData({ cerramiento_id, codigo_id, elemento_id, aislante_id, zona_id })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Row>
      <Col>
      <Row>
        <Col xs="3">
          <ul>
            <li>
              <Link to={`/entity/detalles/add`}>
                Añadir detalles
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs="9">
          <p>La categoría Detalles es demasiada compleja para mostrar todos los reigstros a la vez. Necesitamos que filtres para mostrarte los resultados.</p>
          <hr />
          {data && Object.keys(filter).map(field => (
            <Field 
              label={parseFields(field)} 
              name={field} 
              formik={formik}
              type="select"
              options={data[field] ? parseOption(data[field]) : []}
              />
          ))}
          <Button onClick={formik.handleSubmit} type="button" color="primary">Buscar</Button>
          <hr />
          {result && result.length > 0 
            ? <List noFilter header={headers} data={result} optionForRow={getAction('detalles').options} entity={'detalles'}/> 
            : result ? 'No hay datos' : ''
          }
        </Col>
      </Row>
      </Col>
    </Row>
  )
}

export default PanelDetails
