import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import Field from '../../components/field'
import valeroService from '../../service'
import parseFields, { getInitialValues, singularize, parseValuesToFields } from '../../utils'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

const getRequiredData = (obj) => {
  let newObj = {}
  Object.keys(obj).forEach(i => newObj[i] = Yup.string().required('Es requerido'))
  return newObj
}
export default function Add(props) {
  const history = useHistory()
  const [data, setData] = useState(null)
  const formik = useFormik({
    initialValues: {
      ...getInitialValues(props.match.params.type)
    },
    validationSchema: Yup.object().shape({
      ...getRequiredData(getInitialValues(props.match.params.type))
    }),
    onSubmit: (values) => {
      valeroService.createItemOfEntity(props.match.params.type, values).then(d => {
        history.push(`/${props.match.params.type}`)
      })
    } 
  })

  const getData = async () => {
    const localData = await parseValuesToFields(getInitialValues(props.match.params.type))
    console.log('EL LOCAL', localData)
    setData(localData)
  }
  useEffect(() => {
    getData()
  }, [])
  
  return (
    <>
    <h3>Crear {singularize(props.match.params.type)}:</h3>
    {data && Object.keys(data).filter(field => field !== '_id').map(field => (
      <Field 
        name={field} 
        label={parseFields(field)} 
        formik={formik} 
        placeholder={parseFields(field)}
        type={data[field].type}
        options={data[field].options}
      />
    ))}
    <Button type="button" color="primary" onClick={formik.handleSubmit}>Crear</Button>
    </>
  )
}
