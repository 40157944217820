import React, { useState, useEffect } from 'react'
import valeroService from '../service';

export const AuthContext = React.createContext()

const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)

  const login = (values) => {
    valeroService.login(values).then(user => {
      setUser(user)
      localStorage.setItem('user', JSON.stringify(user))
    })
  }

  useEffect(() => {
    const localUser = localStorage.getItem('user')
    if (localUser) setUser(JSON.parse(localUser))
  }, [])

  const logOut = () => {
    localStorage.removeItem('user')
    setUser(null)
  }

  return(
    <Provider 
      value={{
        user,
        login,
        logOut
      }}>
      {children}
    </Provider>
  )
}

export default AuthProvider