import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Menu from '../menu'

export default function Layout({ children }) {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <Menu />
        </Col>
      </Row>
      <Row>
        <Col>
        {children}
        </Col>
      </Row>
    </Container>
  )
}