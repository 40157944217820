import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import Field from '../../components/field'
import valeroService from '../../service'
import parseFields, { getInitialValues, singularize, parseValuesToFields } from '../../utils'
import usePrevious from '../../components/usePrevios'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import useAuth from '../../context/useAuth'

export default function Edit(props) {
  const history = useHistory()
  const prevParams = usePrevious(props.match.params);
  const [rawData, setRawData] = useState(null)
  const [data, setData] = useState(null)
  const [fields, setFields] = useState(null)
  const { logOut } = useAuth()
  const formik = useFormik({
    initialValues: {
      ...getInitialValues(props.match.params.type)
    },
    onSubmit: (values) => {
      valeroService.updateItemOfEntity(props.match.params.type, {...rawData, ...values}).then(d => {
        history.push(`/entity/${props.match.params.type}`)
      })
    } 
  })

  const getFields = async (values) => {
    const d = await parseValuesToFields(values)
    setFields(d)
  }

  useEffect(() => {
    const { type, id } = props.match.params
      if ((prevParams === undefined) || (prevParams.type !== type && prevParams.id !== id)) {
        valeroService.getItemOfEntity(type, id).then(res => {
          const { created_at, updated_at, __v, _id, ...rest } = res;
          if(!created_at) logOut()
          formik.setValues({
            ...rest
          })
          getFields(rest)
          setData(rest)
          setRawData(res)
        })
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevParams, props.match.params])
  
  if (!props.match.params.id) return 'No id provided'
  return (
    <>
    <h3>Editar {singularize(props.match.params.type)}:</h3>
    {fields && Object.keys(fields).filter(field => field !== '_id').map(field => (
      <Field 
        label={parseFields(field)} 
        formik={formik} 
        name={field} 
        placeholder={parseFields(field)}
        type={fields[field].type}
        options={fields[field].options}
        />
    ))}
    <Button type="button" color="primary" onClick={formik.handleSubmit}>Editar</Button>
    </>
  )
}
