import React, { useState, useEffect, memo } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

import List from '../../components/list'
import valeroService from '../../service'


export const getAction = (entity) => {
  const headers = {
    cerramientos: ['name'],
    codigos: ['name'],
    elementos: ['name'],
    productos: ['name'],
    zonas: ['name'],
    detalles: ['name']
  }
  return {
    action: () => valeroService.getAllEntity(entity),
    headers: headers[entity],
    options: [{
      name: 'Editar'
    }, {
      name: 'Borrar',
      action: (item) => valeroService.deleteItemOfEntity(entity, item._id)
    }]
  }
}


const Panel = (props) => {
  const [entity, setEntity] = useState(props.match.params.type)
  const [data, setData] = useState(null)
  const [headers, setHeaders] = useState(null)

  useEffect(() => {
    if (entity) {
      const newAction = getAction(entity)
      newAction.action()
      .then(setData)
      setHeaders(newAction.headers)
    }
  }, [entity])

  useEffect(() => {
   setEntity(props.match.params.type)
  }, [props.match.params.type])

  return (
    <Row>
      <Col>
      <Row>
        <Col xs="3">
          <ul>
            <li>
              <Link to={`/entity/${entity}/add`}>
                Añadir {entity}
              </Link>
            </li>
            <li>
              <Link to={`/entity/${entity}`}>
                Lista {entity}
              </Link>
            </li>
          </ul>
        </Col>
        <Col xs="9">
          {data &&
            <List header={headers} data={data} optionForRow={getAction(entity).options} entity={entity}/> 
          }
        </Col>
      </Row>
      </Col>
    </Row>
  )
}

export default memo(Panel)