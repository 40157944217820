import valeroService from "./service"

export default function parseFields(field) {

  switch(field) {
    case 'name':
      return 'Nombre'
    case 'cerramiento_id':
      return 'Cerramiento'
    case 'codigo_id':
      return 'Código'
    case 'elemento_id':
      return 'Elemento'
    case 'aislante_id':
      return 'Producto'
    case 'zona_id':
      return 'Zona'
    case 'espesor':
      return 'Espesor'
    case 'nota':
      return 'Nota'
    case 'leyenda':
      return 'Leyenda'
    default:
      return field.toUpperCase()
  }

}

export const singularize = (str) => {
 return str.substring(0, str.length - 1)
}

export const getInitialValues = (entity) => {
  switch(entity) {
    case 'codigos':
      return { name: '', cerramiento_id: '' }
    case 'elementos':
      return { name: '', codigo_id: '' }
    case 'productos':
      return { name: '', web: '' }
    case 'detalles':
      return { 
        cerramiento_id: '',
        codigo_id: '',
        elemento_id: '',
        aislante_id: '',
        zona_id: '',
        espesor: '',
        utr: '',
        urec: '',
        chi: '',
        leyenda: '',
        img: '',
        pdf: '',
        nota: ''
       }
    default:
      return { name: '' }
  }
}
export const parseOption = (arr) => {
  return arr.map(itm => ({ value: itm._id, name: itm.name }))
}
const getOptions = async (key) => {
  let data;
  switch(key) {
    case 'cerramiento_id':
      data = 'cerramientos'
      break;
    case 'codigo_id':
      data = 'codigos'
      break;
    case 'elemento_id':
      data = 'elementos'
      break;
    case 'aislante_id':
      data = 'productos'
      break;
    case 'zona_id':
      data = 'zonas';
      break;
    default:
      return data
  }
  return await valeroService.getAllEntity(data).then(d => parseOption(d))
}

export const parseValuesToFields = async (values) => {
  const selects = [
    "cerramiento_id",
    "codigo_id",
    "elemento_id",
    "aislante_id",
    "zona_id",
  ];

  const newObj = { ...values };

  for (let key in newObj) {
    console.log("key", key);
    if (selects.includes(key)) {
      newObj[key] = { type: "select", options: await getOptions(key) };
    } else {
      newObj[key] = { type: "text" };
    }
  }
  return newObj;
}