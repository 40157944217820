import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import Field from '../../components/field'
import valeroService from '../../service'
import parseFields from '../../utils'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import useAuth from '../../context/useAuth'

const initialValues = {
  cerramiento_id: '',
  codigo_id: '',
  elemento_id: '',
  aislante_id: '',
  zona_id: ''
}

const selects = ['cerramiento_id', 'codigo_id', 'elemento_id', 'aislante_id', 'zona_id']
const EditDetails = (props) => {
  const history = useHistory()
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      valeroService.updateItemOfEntity('detalles', { ...data.detalle, ...values }).then(r => {
        history.push(`/entity/detalles`)
      })
    }
  })
  const { logOut } = useAuth()
  const [data, setData] = useState(null)

  const getData = async () => {
    const detalle = await valeroService.getItemOfEntity('detalles', props.match.params.id)
    const { __v, created_at, updated_at, ...detalleToState } = detalle
    if (!created_at) logOut()
    const cerramiento_id = await valeroService.getAllEntity('cerramientos')
    const codigo_id = await valeroService.getAllEntity('codigos')
    const elemento_id = await valeroService.getAllEntity('elementos')
    const aislante_id = await valeroService.getAllEntity('productos')
    const zona_id = await valeroService.getAllEntity('zonas')

    formik.setValues({
      cerramiento_id: detalle.cerramiento_id,
      codigo_id: detalle.codigo_id,
      elemento_id: detalle.elemento_id,
      aislante_id: detalle.aislante_id,
      zona_id: detalle.zona_id,
      chi: detalle.chi,
      espesor: detalle.espesor,
      img: detalle.img,
      leyenda: detalle.leyenda,
      nota: detalle.nota,
      pdf: detalle.pdf,
      urec: detalle.urec,
      utr: detalle.utr,
    })
    setData({ detalle: detalleToState, cerramiento_id, codigo_id, elemento_id, aislante_id, zona_id })
  }

  useEffect(() => {
    getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <h3>Editar detalles:</h3>
      {data?.detalle && Object.keys(data.detalle).filter(itm => itm !== '_id').map(field => selects.includes(field) 
      ? (
        <Field 
          formik={formik}
          name={field}
          label={parseFields(field)}
          type="select"
          options={data[field].map(f => ({ value: f._id, name: f.name }))}
          value={formik.values[field]}
        />
      )
      : (
        <Field 
          formik={formik}
          name={field}
          label={field}
          value={formik.values[field]}
        />
      )
      )}
      <Button color="primary" onClick={formik.handleSubmit}>Actualizar</Button>
    </div>
  )
}

export default EditDetails
