import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'

import { COLORS } from '../styles'

const List = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 4px solid #ccc;
  margin-bottom: 50px;
  li {
    &.active-menu{
      font-weight: bold;
      border-bottom: 1px solid ${COLORS.secondary};
    }
    a{
      cursor: pointer;
      color: ${COLORS.primary};
      &:hover{
        color: ${COLORS.secondary};
        text-decoration: none;
      }
    }
  }
`

export default function Menu(props) {
  const [active, setActive] = useState('cerramientos')
  const { params } = useRouteMatch()
  useEffect(() => {
    setActive(params.type)
  }, [params.type])

  return (
    <List>
      <li className={active === 'cerramientos' ? 'active-menu' : ''}>
        <Link to="/entity/cerramientos">Cerramientos</Link>
      </li>
      <li className={active === 'codigos' ? 'active-menu' : ''}>
        <Link to="/entity/codigos">Códigos</Link>
      </li>
      <li className={active === 'elementos' ? 'active-menu' : ''}>
        <Link to="/entity/elementos">Elementos</Link>
      </li>
      <li className={active === 'productos' ? 'active-menu' : ''}>
        <Link to="/entity/productos">Productos</Link>
      </li>
      <li className={active === 'zonas' ? 'active-menu' : ''}>
        <Link to="/entity/zonas">Zonas</Link>
      </li>
      <li className={active === 'detalles' ? 'active-menu' : ''}>
        <Link to="/entity/detalles">Detalles</Link>
      </li>
    </List>
  )
} 