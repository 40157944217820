import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import debounce from 'just-debounce-it';

import { COLORS } from '../styles'
import Field from '../field'
import parseFields from '../../utils'

const Option = styled.span`
  cursor: pointer;
  font-size: smaller;
  padding: 0 5px;
  color: ${COLORS.primary};
  display:flex;
  &:first-child{
    border-right: 1px solid #ccc;
  }
  &:hover {
    color: ${COLORS.secondary};
  }
`

function Options({ item, options, entity, deleteAction }) {
  return options.map(option => {
    if (option.name !== 'Editar') return (
      <Option onClick={() => option.action(item).then(res => deleteAction(res))}>{option.name}</Option>
    )
    return <Link to={`/entity/${entity}/edit/${item._id}`}><Option>{option.name}</Option></Link>
  })
}

const filterSearch = debounce((newvalue, setList, rawList) => {
  const newList = rawList.filter(item => item.name.toLowerCase().includes(newvalue.toLowerCase()))
  setList(newList)
}, 500)

export default function List({ noFilter, data, optionForRow, header, entity }){
  const [rawList, setRawList] = useState(data)
  const [list, setList] = useState(data)
  const [value, setValue] = useState('')


  const handleSearch = (e) => {
    const { value: inputValue } = e.target
    setValue(inputValue)
    filterSearch(inputValue, setList, rawList)
  }

  const deleteAction = (item) => {
    setList(old => old.filter(oldItem => oldItem._id !== item._id))
  }
  useEffect(() => {
    setList(data)
    setRawList(data)
  }, [data])

  return (
    <>
    {!noFilter && header.map(headerItem => (
      <Field placeholder="Filtrar por nombre" onChange={handleSearch} value={value} name={headerItem} />
    ))}
    
    <Table hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          {header.map(headerItem => <th>{parseFields(headerItem)}</th>)}
          <th className="d-flex justify-content-end px-3">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {list.map((itemData, i) => (
          <tr>
          <th>
          {i}
            </th>
            {header.map((headerItem) => {
              if (headerItem.includes('_id')) return <td>{itemData[headerItem].name}</td>
              return <td>{itemData[headerItem]}</td>
            })}
            {optionForRow && <td className="d-flex justify-content-end">
              <Options item={itemData} options={optionForRow} deleteAction={deleteAction} entity={entity}/>
            </td>}
          </tr>

        ))}
      </tbody>
    </Table>
    </>
  )
}