import axios from 'axios'

class ValeroService{
  constructor() {
    this.service = axios.create({
      baseURL: 'https://api.grupovalero.com',
      withCredentials: true // Para que viajen tus datos por cookies
    })
  }

  login(user) {
    return this.service.post('/auth/login', user).then(({data}) => data)
  }
  
  logOut() {
    return this.service.post('/auth/logout').then(data => data)
  }

  getAllEntity(entity) {
    return this.service.get(`/${entity}`).then(({data}) => data)
  }

  getItemOfEntity(entity, id) {
    return this.service.get(`/${entity}/${id}`).then(({data}) => data)
  }

  deleteItemOfEntity(entity, id) {
    return this.service.delete(`/${entity}/${id}`).then(({data}) => data)
  }

  updateItemOfEntity(entity, obj) {
    return this.service.put(`/${entity}/${obj._id}`, obj).then(({data}) => data)
  }

  createItemOfEntity(entity, obj) {
    return this.service.post(`/${entity}`, obj).then(({data}) => data).catch(e => e.status === 401 && this.logOut())
  }

  getForm(inputs){
    const {cerramiento_id, codigo_id, elemento_id, aislante_id, zona_id} = inputs;
    const url = cerramiento_id && codigo_id && elemento_id && aislante_id && zona_id
      ? `cerramiento=${cerramiento_id}&codigo=${codigo_id}&elemento=${elemento_id}&aislante=${aislante_id}&zona=${zona_id}`
      : cerramiento_id && codigo_id && elemento_id && aislante_id
        ?  `cerramiento=${cerramiento_id}&codigo=${codigo_id}&elemento=${elemento_id}&aislante=${aislante_id}`
        : cerramiento_id && codigo_id && elemento_id
        ?  `cerramiento=${cerramiento_id}&codigo=${codigo_id}&elemento=${elemento_id}`
          : cerramiento_id && codigo_id
          ? `cerramiento=${cerramiento_id}&codigo=${codigo_id}`
            : `cerramiento=${cerramiento_id}`;
    return this.service.get(`/form?${url}`).then(({data})=>data)
}

}

const valeroService = new ValeroService()

export default valeroService