import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { useFormik } from 'formik';

import Field from '../../components/field';
import useAuth from '../../context/useAuth';

const fields = [
  {
    name: 'username',
    type: 'text',
    placeholder: 'Usuario',
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Contraseña'
  }
]

export default function Login (){
  const { login } = useAuth()
  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    onSubmit: values => {
      login(values)
    }
  })
  return (
    <Row style={{height: '100vh'}}>
      <Col className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
        <Row>
          <Col className="d-flex flex-column align-items-center">
          {fields.map(item => (
            <Field 
              placeholder={item.placeholder}
              type={item.type}
              name={item.name} 
              key={item.name} 
              formik={formik} 
            />
          ))}
          <Button type="button" color="primary" onClick={formik.handleSubmit}>
            Entrar
          </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}