import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import PrivateRoute from './components/routes/privateRoute'
import PublicRoute from './components/routes/publicRoute'
import Add from './pages/add'
import Edit from './pages/edit'
import EditDetails from './pages/editDetails'


import Login from './pages/login'
import Panel from './pages/panel'
import PanelDetails from './pages/panelDetails'

export default function App() {
  return(
        <Switch>
	  <PrivateRoute path="/entity/:type/add" component={Add}/>
          <PrivateRoute path="/entity/detalles/edit/:id" component={EditDetails} />
          <PrivateRoute path="/entity/detalles" component={PanelDetails} />
          <PrivateRoute path="/entity/:type/edit/:id" component={Edit}/>
          <PrivateRoute path="/entity/:type" component={Panel} />
          <PublicRoute path="/login" component={Login} />
          <Redirect to="/login" />
        </Switch>
  )
}
